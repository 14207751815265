// src/services/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCugxCypKHE1Sdp0pPugO7j-w9n13fapDQ",
  authDomain: "skuld-15670.firebaseapp.com",
  projectId: "skuld-15670",
  storageBucket: "skuld-15670.appspot.com",
  messagingSenderId: "564079110359",
  appId: "1:564079110359:web:30cedc09f284fb52e3e892",
  measurementId: "G-MRBRNQ65QZ"
};

let app;
let db;
let auth;
let functions;

try {
  app = initializeApp(firebaseConfig);
  db = getFirestore(app);
  auth = getAuth(app);
  functions = getFunctions(app);

  // Uncomment these lines if you're using Firebase Emulators
  // connectFirestoreEmulator(db, 'localhost', 8080);
  // connectFunctionsEmulator(functions, 'localhost', 5001);

} catch (error) {
  console.error("Error initializing Firebase:", error);
}

export { app, db, auth, functions };
export const googleProvider = new GoogleAuthProvider();