// src/components/Header.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { auth } from '../services/firebase';
import { signOut } from 'firebase/auth';
import './Header.css';

function Header() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <header className="header">
      <div className="logo">◻ AI Stock Predictor</div>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          {user && <li><Link to="/predict">Predict</Link></li>}
          {user && <li><Link to="/purchase">Buy Tokens</Link></li>}
        </ul>
      </nav>
      <div className="auth">
        {user ? (
          <>
            <span className="user-email">{user.email}</span>
            <button onClick={handleSignOut} className="btn btn-secondary">Sign Out</button>
          </>
        ) : (
          <Link to="/signin" className="btn">Sign In</Link>
        )}
      </div>
    </header>
  );
}

export default Header;