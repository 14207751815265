// src/pages/PurchaseTokens.js
import React, { useState, useEffect  } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useAuth } from '../context/AuthContext';
import { purchaseTokens, getUserTokens } from '../services/userService';
import './PurchaseTokens.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const PurchaseTokens = () => {
  const [amount, setAmount] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [currentTokens, setCurrentTokens] = useState(0);
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth();

  useEffect(() => {
    const fetchTokens = async () => {
      if (user) {
        try {
          const tokens = await getUserTokens(user.uid);
          setCurrentTokens(tokens);
        } catch (error) {
          console.error('Error fetching tokens:', error);
        }
      }
    };
    fetchTokens();
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    if (!stripe || !elements) {
      setError("Stripe has not loaded. Please try again later.");
      setLoading(false);
      return;
    }

    try {
      const result = await purchaseTokens(amount);
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(result.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: user.email,
          },
        },
      });

      if (stripeError) {
        setError(`Payment failed: ${stripeError.message}`);
      } else if (paymentIntent.status === 'succeeded') {
        setSuccess(true);
        setCurrentTokens(currentTokens + amount);
      } else {
        setError(`Unexpected payment status: ${paymentIntent.status}`);
      }
    } catch (error) {
      setError(`Error: ${error.message}`);
    }

    setLoading(false);
  };

  const handleAmountChange = (e) => {
    const value = parseInt(e.target.value);
    setAmount(isNaN(value) ? 0 : Math.max(1, Math.min(100, value)));
  };

  if (success) {
    return (
      <div className="purchase-tokens">
        <h2>Purchase Successful!</h2>
        <p>You have successfully purchased {amount} tokens.</p>
      </div>
    );
  }

  return (
    <div className="purchase-tokens">
      <h2>Purchase Tokens</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="amount">Number of Tokens:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={handleAmountChange}
            min="1"
            max="100"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="card-element">Credit or debit card</label>
          <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
        </div>
        <button type="submit" className="btn" disabled={!stripe || loading}>
          {loading ? 'Processing...' : `Purchase for $${amount}`}
        </button>
      </form>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default PurchaseTokens;