// src/services/userService.js
import { db } from './firebase';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
const handleFirestoreError = (error) => {
  console.error("Firestore error:", error);
  if (error.code === 'failed-precondition' || error.code === 'unavailable') {
    return { error: "Unable to connect to the database. Please check your internet connection and try again." };
  }
  return { error: "An unexpected error occurred. Please try again later." };
};

export const initializeUserData = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      await setDoc(userRef, {
        tokens: 10
      });
    }
    return { success: true };
  } catch (error) {
    return handleFirestoreError(error);
  }
};

const getUserTokensFunction = httpsCallable(functions, 'getUserTokens');

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export const getUserTokens = async (userId, retries = 0) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      return { tokens: userSnap.data().tokens };
    } else if (retries < MAX_RETRIES) {
      // If the document doesn't exist yet, wait and try again
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return getUserTokens(userId, retries + 1);
    } else {
      throw new Error('User document not found after maximum retries');
    }
  } catch (error) {
    console.error("Error getting user tokens:", error);
    throw error;
  }
};

export const spendToken = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const currentTokens = userSnap.data().tokens;
      if (currentTokens > 0) {
        await updateDoc(userRef, {
          tokens: currentTokens - 1
        });
        return { success: true, tokens: currentTokens - 1 };
      }
    }
    return { success: false, error: "Not enough tokens" };
  } catch (error) {
    return handleFirestoreError(error);
  }
};

const purchaseTokensFunction = httpsCallable(functions, 'purchaseTokens');

export const purchaseTokens = async (amount) => {
  try {
    console.log('Calling purchaseTokens function with amount:', amount);
    const result = await purchaseTokensFunction({ amount });
    console.log('purchaseTokens function result:', result);
    return result.data;
  } catch (error) {
    console.error("Error purchasing tokens:", error);
    if (error.code === 'functions/internal') {
      throw new Error(`An internal error occurred. Please try again later. (${error.message})`);
    } else if (error.code === 'functions/invalid-argument') {
      throw new Error(`Invalid token amount. ${error.message}`);
    } else {
      throw new Error(`Failed to initiate token purchase: ${error.message}`);
    }
  }
};