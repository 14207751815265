import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getUserTokens, spendToken } from '../services/userService';
import { fetchPrediction } from '../services/predictionService';
import DatePicker from 'react-datepicker';
import stockList from './utils/stockList'; // Ensure your stockList.js exports the stockList
import "react-datepicker/dist/react-datepicker.css";
import './Predict.css';


const Predict = () => {
  const { user } = useAuth();
  const [symbolInput, setSymbolInput] = useState(''); // State for the input value
  const [symbol, setSymbol] = useState(''); // State for the selected symbol
  const [stockOptions, setStockOptions] = useState([]);
  const [predictionDateInput, setPredictionDateInput] = useState(new Date()); // Separate input state for prediction date
  const [predictionDate, setPredictionDate] = useState(new Date()); // State for the selected prediction date
  const [prediction, setPrediction] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedModelInput, setSelectedModelInput] = useState('ARIMA'); // Separate input state for model
  const [selectedModel, setSelectedModel] = useState('ARIMA'); // State for the selected model
  const [isValidSymbol, setIsValidSymbol] = useState(true); // New state for input validity

  useEffect(() => {
    ;
    const fetchTokens = async () => {
      if (user) {
        try {
          const result = await getUserTokens(user.uid);
          setTokens(result.tokens);
          setError(null);
        } catch (error) {
          console.error("Error fetching tokens:", error);
          setError("Failed to fetch user tokens. Please try again.");
          setTokens(null);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchTokens();
  }, [user]);

  // Filter stock options based on user input
  const handleSymbolChange = (e) => {
    const value = e.target.value;
    setSymbolInput(value); // Update the input state
    
    // Validate if the symbol exists in the stockList
    const stockExists = stockList.list.some(stock => stock[0].toLowerCase() === value.toLowerCase());
    setIsValidSymbol(stockExists); // Update validity state

    // Filter the stock list based on the input value
    if (value.length > 0) {
      const filteredOptions = stockList.list.filter(stock =>
        stock[0].toLowerCase().includes(value.toLowerCase()) ||
        stock[1].toLowerCase().includes(value.toLowerCase())
      );
      setStockOptions(filteredOptions);
    } else {
      setStockOptions([]);
    }
  };

  const handleStockSelect = (selectedStock) => {
    setSymbolInput(selectedStock[0]); // Update input value
    setSymbol(selectedStock[0]); // Update selected symbol state
    setStockOptions([]); // Clear the options
    setIsValidSymbol(true); // Set validity to true when a valid stock is selected
  };

  const handlePredict = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    // Use the input values for prediction
    const selectedSymbol = symbolInput; 
    const selectedDate = predictionDateInput; 
    const selectedModel = selectedModelInput;

    // Validate if the selected symbol exists in the stockList
    const stockExists = stockList.list.some(stock => stock[0] === selectedSymbol);

    if (!stockExists) {
      alert('Stock symbol not found! Please select a valid stock from the dropdown.');
      setLoading(false);
      return; // Stop execution if stock symbol is invalid
    }

    if (tokens > 0) {
      const confirmed = window.confirm("Do you want to use 1 token for this prediction?");
      if (confirmed) {
        try {
          const tokenSpent = await spendToken(user.uid);
          if (tokenSpent) {
            const result = await fetchPrediction(selectedSymbol, selectedDate, selectedModel);
            setPrediction(result);
            console.log(result)
            setTokens(prevTokens => prevTokens - 1);
            setPredictionDate(selectedDate); // Update the prediction date state
            setSelectedModel(selectedModel); // Update the selected model state
          } else {
            setError("Failed to use token. Please try again.");
          }
        } catch (error) {
          setError(error.message || "Error making prediction. Please try again.");
        }
      }
    } else {
      setError("You don't have enough tokens. Please purchase more.");
    }
    setLoading(false);
  };

  // ... (rest of your code)

return (
  <div className="predict-container">
    <h2>Predict Stock Price</h2>
    <div className="explanation">
      <p>Our platform uses a combination of historical data and sentiment analysis to reach a prediction.</p>
      <p>Choose from three advanced models: ARIMA, Prophet, or LSTM.</p>
      <p><a href="https://neptune.ai/blog/arima-vs-prophet-vs-lstm" target="_blank" rel="noopener noreferrer">Learn more about these models</a></p>
    </div>
    {loading ? (
      <p>Loading user data...</p>
    ) : error ? (
      <p className="error">{error}</p>
    ) : (
      <>
        <p>You have {tokens} tokens remaining.</p>
        <form onSubmit={handlePredict}>
          <div className="form-group">
            <label htmlFor="symbol">Stock Symbol:</label>
            <div className="stock-input-container">
              <input
                type="text"
                id="symbol"
                value={symbolInput} // Bind input to symbolInput
                onChange={handleSymbolChange}
                placeholder="Search for a stock"
                required
                style={{ borderColor: isValidSymbol ? 'initial' : 'red' }} // Conditional border color
              />
              {stockOptions.length > 0 && (
                <ul className="stock-options">
                  {stockOptions.map((stock, index) => (
                    <li key={index} onClick={() => handleStockSelect(stock)}>
                      {stock[0]} - {stock[1]} {/* Display symbol and name */}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="date">Prediction Date:</label>
            <DatePicker
              id="date"
              selected={predictionDateInput} // Bind to predictionDateInput
              onChange={(date) => setPredictionDateInput(date)} // Update input state only
              minDate={new Date()}
            />
          </div>
          <div className="form-group">
            <label htmlFor="model">Select Model:</label>
            <select
              id="model"
              value={selectedModelInput} // Bind to selectedModelInput
              onChange={(e) => setSelectedModelInput(e.target.value)} // Update input state only
            >
              <option value="ARIMA">ARIMA</option>
              <option value="Prophet">Prophet</option>
              <option value="LSTM">LSTM</option>
            </select>
          </div>
          <button type="submit" className="btn" disabled={loading}>
            {prediction ? 'New Prediction' : loading ? 'Predicting...' : 'Predict'}
          </button>
        </form>
      </>
    )}
    {prediction && (
      <div className="prediction-result">
        <h3>Prediction Result:</h3>
        <p>Stock: {symbol}</p> {/* Display the final selected symbol */}
        <p>Date: {predictionDate.toDateString()}</p>
        <p>Model Used: {selectedModel}</p>
        <p>Predicted Price: ${prediction.price.toFixed(2)}</p>
        <p>Confidence: {(prediction.confidence * 100).toFixed(2)}%</p>
      </div>
    )}
    {tokens === 0 && (
      <p>
        Need more tokens? <Link to="/purchase">Purchase here</Link>
      </p>
    )}
  </div>
);


}

export default Predict;
