// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Home from './pages/Home';
import Predict from './pages/Predict';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import PurchaseTokens from './pages/PurchaseTokens';
import Header from './components/Header';
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
// Replace with your actual Stripe publishable key
const stripePromise = loadStripe('pk_live_51IB2OnFofG0T6L9bXptLm8q9uYQ9zWrzm8lPSCHlLs7m8bZvQEttXQNdEp3FjjdQqrsawJbL4nPoW08BxZL0U5nL00YKfwHTYY');

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return <div>Loading...</div>;
  if (!user) return <Navigate to="/signin" />;
  return children;
};

const ConditionalHeader = () => {
  const location = useLocation();
  return location.pathname !== '/' ? <Header /> : null;
};

function App() {
  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <Router>
          <div className="App">
            <ConditionalHeader />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route 
                path="/predict" 
                element={
                  <ProtectedRoute>
                    <Predict />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/purchase" 
                element={
                  <ProtectedRoute>
                    <PurchaseTokens />
                  </ProtectedRoute>
                } 
              />
            </Routes>
          </div>
        </Router>
      </Elements>
    </AuthProvider>
  );
}

export default App;