// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Home.css';

function Home() {
  const { user } = useAuth();

  return (
    <div className="home">
      <main className="main-content">
        <div className="text-content">
          <h1>Generative AI for Stock Prediction</h1>
          <p className="subtitle monospace">
            AI-NATIVE APPLICATIONS BUILT FOR THE FUTURE, POWERED BY
            DOMAIN-SPECIFIC MODELS OPTIMIZED FOR FINANCIAL SERVICES.
          </p>
          <div className="cta-buttons">
            {user ? (
              <>
                <Link to="/predict" className="btn">PREDICT NOW</Link>
                <Link to="/purchase" className="btn btn-secondary">BUY TOKENS</Link>
              </>
            ) : (
              <>
                <Link to="/signin" className="btn">SIGN IN</Link>
                <Link to="/signup" className="btn btn-secondary">SIGN UP</Link>
              </>
            )}
          </div>
        </div>
        <div className="visual-element">
          <div className="placeholder-graphic"></div>
        </div>
      </main>
      <footer className="footer">
        <div className="dotted-line"></div>
        <div className="products monospace">
          <span>PRODUCTS —</span>
          <ul>
            <li>SKULD</li>
            <li>ARISTOTLE</li>
          </ul>
        </div>
        <div className="footer-links monospace">
          <span>© LIVE</span>
          <a href="/privacy">PRIVACY POLICY</a>
          <a href="terms">TERMS OF USE</a>
        </div>
      </footer>
    </div>
  );
}

export default Home;