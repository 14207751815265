import React from 'react';


const Terms = () => {
  return (
    <div className="terms-of-service-container">
      <h1>Terms of Service</h1>
      <p>Last updated: 10/3/2024</p>

      <section className="terms-section">
        <h2>1. Acceptance of Terms</h2>
        <p>
          By using our application, you agree to comply with and be bound by these Terms of Service. If you do not agree with any part of these terms, you must not use our app.
        </p>

        <h2>2. Purpose</h2>
        <p>
          Our app provides predictions on stock prices using machine learning algorithms. Please note that these predictions are for entertainment purposes only and should not be considered as financial or investment advice.
        </p>

        <h2>3. User Responsibilities</h2>
        <p>
          You are responsible for any decisions made based on the information provided by our app. We recommend consulting a qualified financial advisor before making any investment decisions.
        </p>

        <h2>4. Limitation of Liability</h2>
        <p>
          Under no circumstances shall we be liable for any direct, indirect, incidental, or consequential damages arising out of or in any way connected with your use of our app.
        </p>

        <h2>5. Changes to Terms</h2>
        <p>
          We reserve the right to modify these Terms of Service at any time. We will notify you of any changes by updating the date at the top of this page. Your continued use of the app after such changes constitutes your acceptance of the new Terms.
        </p>
      </section>
    </div>
  );
};

export default Terms;
