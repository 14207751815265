import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';

const predict = httpsCallable(functions, 'predict');

export const fetchPrediction = async (symbol, date) => {
  try {
    const result = await predict({ symbol, date: date.toISOString() });
    return result.data;
  } catch (error) {
    console.error("Error fetching prediction:", error);
    throw error;
  }
};

