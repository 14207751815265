import React from 'react';


const Privacy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Last updated: 10/3/2024</p>

      <section className="privacy-section">
        <h2>1. Information We Collect</h2>
        <p>
          We may collect personal information that you provide to us when using our app, such as your name, email address, and usage data. This information is used to improve our services and enhance your experience.
        </p>

        <h2>2. How We Use Your Information</h2>
        <p>
          The information we collect may be used for the following purposes:
        </p>
        <ul>
          <li>To provide and maintain our app.</li>
          <li>To notify you about changes to our app.</li>
          <li>To provide customer support.</li>
          <li>To gather analysis or valuable information so that we can improve our app.</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information to outside parties. We may share information with trusted third parties who assist us in operating our app, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
        </p>

        <h2>4. Security of Your Information</h2>
        <p>
          The security of your personal information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
        </p>

        <h2>5. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </section>
    </div>
  );
};

export default Privacy;
